<template>
    <questions-results
        passed-type="student"
        :passed-uuid="$auth.user.uuid"
    />
</template>

<script>
import QuestionsResults from '@apps/questions/components/QuestionsResults'
export default {
    name: 'QuestionsResultsPage',
    components: { QuestionsResults }
}
</script>

<style lang=scss>

</style>
